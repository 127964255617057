import React, {useRef, useEffect} from 'react';
import './Burger.css';
import {useLocation, useNavigate} from 'react-router-dom';
import { educationLevels } from "../EducationLevelSelection/EducationLevelSelection"

const Burger = ({onClose}) => {
    const location = useLocation();
    const menuRef = useRef();

    useEffect(() => {
        const scrollbarWidth = window.innerWidth - document.documentElement.clientWidth;
        const header = document.querySelector('.header__container');

        if (header) {
            document.body.style.paddingRight = `${scrollbarWidth}px`;
        }

        document.body.classList.add('no-scroll');

        function handleClickOutside(event) {
            if (menuRef.current && !menuRef.current.contains(event.target)) {
                onClose();
            }
        }

        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            if (header) {
                document.body.style.paddingRight = '';
            }
            document.body.classList.remove('no-scroll');
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [onClose]);

    const links = [
        {name: "Главная", path: "/main"},
        {name: 'Календарь', path: '/calendar'},
        {name: 'Бакалавриат', path: 'https://www.art-gzhel.ru/abitur/bachelor/'},
        {name: "Специалитет", path: 'https://www.art-gzhel.ru/abitur/special/'},
        {name: "Магистратура", path: 'https://www.art-gzhel.ru/abitur/magistr/'},
        {name: "Аспирантура", path: 'https://www.art-gzhel.ru/abitur/aspirant/'},
        {name: "Сведение о ходе приема", path: '/documents/bachelor'},
        // ...educationLevels.map(level => ({
        //     name: level.label,
        //     path: `/documents/${level.key}`,
        // }))
    ];

    const handleLinkClick = (event, path, isInternal) => {
        event.preventDefault();
        if (isInternal) {
            const baseUrl = window.location.origin;
            window.location.href = `${baseUrl}${path}`;
        } else {
            window.open(path, '_blank');
        }
        onClose();
    };

    return (
        <>
            <div className='burger' onClick={onClose}>
                <div className='burger__container' onClick={(e) => e.stopPropagation()} ref={menuRef}>
                    <button type='button' className='burger__button_close' onClick={() => onClose()}/>
                    <nav className="burger__menu">
                        {links.map((link, index) => (
                            <a
                                key={index}
                                href={link.path}
                                className={location.pathname.includes(link.path)  ? 'burger__menu_link burger__menu-link-active' : 'burger__menu_link'}
                                onClick={(event) => handleLinkClick(event, link.path, link.path.startsWith('/'))}
                                target={link.path.startsWith('/') ? '_self' : '_blank'}
                                rel={link.path.startsWith('/') ? '' : 'noopener noreferrer'}
                            >
                                {link.name}
                            </a>
                        ))}
                    </nav>
                </div>
            </div>
        </>
    );
};

export default Burger;
